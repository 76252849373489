:root {
  --primaryColor: #5B9DFF;/* Main */
  --primaryColorLight: #DEEBFF; /* Tint */
  --secondaryColor: #FF8777; /* Accent */
  --secondaryColorLight: #FF877775;
  --primaryText: #2d2d2d;  /* Font */
  --mainBg: #f7f7f7;  /* Background */
  --primaryTextLight: #2d2d2d40;
  --titleText: #6a52ff;

  --secondaryText: white;
  --secondaryBg: white; /* Quetions */
  --feedbackStarColor: var(--primaryColor);
  --buttonBackground: var(--primaryColor);
  --featureYouGetBg: var(--primaryColorLight);
  --plansInactiveColor: var(--primaryColorLight);
  --checkboxMarkCheckedBackground: var(--primaryColor);
  --injectedPaymentPrimaryColor: var(--buttonBackground);
  --moneyBackTitleColor: #2d2d2d;
  --titleH2Color: var(--primaryText);

  --payModalPrimaryColor: var(--primaryColor);

  --upsaleSuccessMessageColor:#CFE1FF;
  --buttonSubscribeBG: #03BD12;
  --subscribeDescriptionImageBG:#5B9DFF33;

}

