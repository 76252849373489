@import "src/styles";

.block {
  background-color: $white;
  max-width: $maxWidthDefault;
  margin: 0 auto;

  .item {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    &:last-child {
      padding-bottom: 0;
    }


    .text {
      font-size: $fontSize14;
      line-height: $fontSize18;
      padding-inline-start: 10px;
      width: 95%;
    }
  }
}

.totalSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .totalTitle {
    font-size: 18px;
    font-weight: 700;
    color: #171717;
  }
  .priceDiscount {
    font-size: 14px;
    font-weight: 600;
    color: #818181;
    margin-right: 10px;
    text-decoration: line-through;
  }
  .discountHint {
    margin-top: 5px;
    color: #03BD12;
    font-size: 14px;
    font-weight: 700;
  }
}

.hint {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #2D2D2D;
}

.featureBlock {
  background: #F7F7F7;
  height: 72px;
  width: 100%;
  display: flex;
  border-radius: 10px;
  padding: 10px;
  gap: 15px;
  align-items: center;
  margin-top: 10px;
  .emoji {
    font-size: 22px;
  }
  .featureTitle {
    font-weight: 500;
    color: #2D2D2D;
    font-size: 16px;
  }
  .featureDescription {
    font-weight: 500;
    color: #818181;
    font-size: 12px;
  }
}

.openButton {
  margin-top: 10px;
  background: #03BD12;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  font-weight: 600;
  color: #FFFFFF;
  font-size: 18px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.closeButton {
  width: 100%;
  height: 45px;
  background-color: transparent;
  justify-content: flex-start;
  display: flex;
  align-items: center;
}