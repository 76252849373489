@import "src/styles/index.scss";

.button {
  min-height: 55px;
  width: 100%;
  max-width: $maxButtonWidth;
  position: relative;
  background: $buttonBackground;
  border-radius: $buttonBorderRadius;
  overflow: hidden;
  box-shadow: $boxShadow;
  transform: translateZ(0);

  &__title {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: $buttonFontSize;
    font-family: $buttonFontFamily;
    font-weight: $buttonFontWeight;
    color: $white;
    letter-spacing: rem(-0.57);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

    > span {
      max-width: 100%;
      display: inline-block;
    }
  }

  &__right-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 33px;
    width: 38px;
  }

  [dir='rtl'] &__right-icon {
    left: 10px;
    right: unset;
    transform: scaleX(-1);
  }

  &:disabled {
    opacity: 0.5;
  }
}


.icon{
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));

}