@import "src/styles";

.popup {
  width: 100%;
  max-width: $maxWidthDefault;
  background-color: $white;
  border-radius: 40px 40px 0 0;
  padding: 20px;
  bottom: 0;
  border: 0;
  outline: none;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 2;
    opacity: 0.5;
  }
}

.overlay {
  position: fixed;
  inset: 0;
  background: rgba(192, 192, 192, 0.55);
  z-index: 23;
}
