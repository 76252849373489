@import "./theme";

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin title-h1 {
  color: $titleH1Color;
  font-size: $titleH1FontSize;
  font-family: $titleH1FontFamily;
  font-weight: $titleH1FontWeight;
  text-align: center;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: center;
}

@mixin title-h2 {
  color: $titleH2Color;
  font-size: $titleH2FontSize;
  font-family: $titleH2FontFamily;
  font-weight: $titleH2FontWeight;
  text-align: center;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
}

@mixin title-h3 {
  color: $titleH3Color;
  font-size: $titleH3FontSize;
  font-family: $titleH3FontFamily;
  font-weight: $titleH3FontWeight;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: 1;
  text-align: center;
}

@mixin text-style {
  font-size: $fontSizeDefault;
  font-weight: $fwn;
  font-style: normal;
  line-height: normal;
}

@mixin input-style {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: $colorBorderField;
  height: 45px;
  width: 100%;
  background-color: $trans;
}

@mixin button-container {
  background-color: $disabledButtonBg;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  justify-content: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

@mixin page-animation {
  display: flex;
  align-items: center;
  height: 20px;
  margin: 10px 0;
}

@mixin screen-animation {
  .animate_fade-out {
    animation: fadeOut ease 0.5s;
  }

  .animate_fade-in {
    animation: fadeIn ease 0.5s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

@mixin question-title-style {
  font-size: $fontSize22;
  font-weight: $fwstb;
  font-style: normal;
  line-height: normal;
  padding: 4vh 25px 3.5vh;
  color: $questionTitleColor;
  letter-spacing: rem(-0.61);
  text-align: center;
  max-width: $maxWidthDefault;
}

@mixin question-main-wrap-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

@mixin question-wrap-style {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@mixin question-answers-list-style {
  display: flex;
  justify-content: center;
}

@mixin question-answers-item-style {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: $maxWidthDefault;
}

@mixin question-button-wrap-style {
  position: fixed;
  width: 100%;
  bottom: 0;
  margin-bottom: 20px;
}

@mixin question-selected-items-style {
  display: flex;
  text-align: center;
  font-weight: $fwb;
  font-size: $fontSize14;
  margin-bottom: 4vh;
}

@mixin media_min_size($minWidth, $minHeight) {
  @media (min-width: $minWidth + px) and (min-height: $minHeight + px) {
    @content;
  }
}

@mixin media_min_height($minHeight) {
  @media (min-height: $minHeight + px) {
    @content;
  }
}

@mixin media_min_width($minWidth) {
  @media (min-width: $minWidth + px) {
    @content;
  }
}
