@import "core/styles";

$mainBgColor: var(--mainBg);

$boxShadow: 0 1px 5px 0 rgba(183, 181, 170, 1);
$shadowColor: rgba(183, 181, 170, 1);

$featureYouGetBg: var(--featureYouGetBg);

$primaryColor: var(--primaryColor);
$secondaryColor: var(--secondaryColor);
$upsaleTextsColor:var(--upsaleTextsColor);

$checkboxMarkBackground: var(--checkboxMarkBackground);
$checkboxMarkCheckedBackground: var(--checkboxMarkCheckedBackground);

$injectedPaymentPrimaryColor: var(--injectedPaymentPrimaryColor);

// sections
$magicGoalsIconColor: var(--primaryColor);

// email
$emailNotificationIconColor: var(--primaryColor);
$emailInputColor: var(--primaryColor);

// questions
$questionActiveTitleColor: var(--primaryColor);
$questionActiveTitleBorderColor: var(--primaryColor);
$questionTitleFontWeight: $fwn;

// buttons
$buttonBackground: var(--buttonBackground);
$buttonFontFamily: $fontFamily;
$buttonSubscribeBG: var(--buttonSubscribeBG);

// links
$preloaderColor: var(--primaryColor);

// form
$colorBorderField: $grayLight;
$inputCaretColor: var(--primaryColor);

// titles
$titleH1Color: var(--titleText);
$titleH2Color: var(--titleH2Color);
$titleH2FontSize: $fontSize22;
$titleH1FontFamily: $fontFamily;
$titleH2FontFamily: $fontFamily;
$titleH3FontFamily: $fontFamily;


// footer
$footerTextColor: var(--primaryText);


// plan
$plansPrimaryColor: var(--primaryColor);
$plansPrimaryBorderColor: var(--primaryColor);
$plansDiscountBgColor: var(--plansDiscountBgColor, $plansPrimaryBorderColor);
$plansPrimaryTitleColor: var(--primaryColor);
$plansInactivePriceColor: var(--primaryColor);
$plansInactiveColor: var(--plansInactiveColor);
$plansBadgeColor: var(--secondaryColor);
$fixedButtonDiscountColor: var(--buttonSubscribeBG);
$reservedPriceColor: var(--primaryColor);
$checkboxMarkCheckedBackground: var(--primaryColor);
$checkboxMarkBackground: var(--primaryColor);

// payment
$payModalPrimaryColor: var(--primaryColor);
$moneyBackBackgroundColor: $white;
$moneyBackTitleColor: var(--moneyBackTitleColor);
$moneyBackIconColor: var(--primaryColor);

// stars
$storeStatsStarColor: var(--primaryColor);
$feedbackStarColor: var(--primaryColor);
$storeStatsBlockBorder: 1px dashed var(--primaryColor);

$paymentOverlay: #f7f7f7;

// cookie modal

$cookieModalIcon: #E0E0E0;
$cookieModalTextColor: #2D2D2D;
$cookieModalBg: $white;
$cookieModalPrimary: var(--primaryColor);
$cookieModalFootnoteText: rgba(#2D2D2D, 0.40);

// upsale
$upsaleSuccessMessageBG:var(--upsaleSuccessMessageColor);

//magic
$checkboxBG:$secondaryColor;

//subscription
$subscribeDescriptionImageBG:var(--subscribeDescriptionImageBG)


