@import "src/styles";

.button {
  width: 90%;
  max-width: $maxButtonWidth;
  background: $buttonSubscribeBG;
}

.buttonMargin {
  margin-top: 15px;
}

.buttonTitleStyle {
  font-size: $fontSize22;
  padding: 10px;
}

.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  margin: auto;
  width: 100%;
  max-width: 450px;
  background: $fixedButtonBoxBg;
  display: flex;
  justify-content: stretch;
  align-content: center;
  z-index: 20;
  visibility: visible;
  opacity: 1;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.invisible {
  visibility: hidden;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.discount {
  margin-right: 4px;
  align-self: center;

  .timer {
    color: $buttonSubscribeBG;
    font-size: $fontSize34;
    font-weight: $fwb;

    .colon {
      opacity: 0.6;
      font-size: $fontSize30;
      vertical-align: top;
      margin: 4px;
    }
  }

  .desc {
    font-size: $fontSize11;
    margin-top: 4px;
    color: $fixedButtonDiscountColor;
    text-align: center;
    letter-spacing: 1px;
    font-weight: 600;
    white-space: nowrap;
  }
}

.content {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;

  .button {
    width: 90%;

    div + div {
      position: absolute;
    }
  }
}
