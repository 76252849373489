@import "src/styles";

.wrap {
  width: 100%;
  margin: 10px 0 6px;
  position: relative;

  .promo_badge {
    width: 72px;
    height: 22px;
    background-color: #f27067;
    border-radius: 4px;
    color: $plansPrimaryTextColor;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
    font-family: $fontFamily;
    font-size: $fontSize16;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .container {
    width: 100%;
    display: flex;
    border-radius: 15px;
    background: $plansInactiveBg;
    border: 2px solid $plansInactiveBorderColor;
    color: $grayOpacity;
    justify-content: space-between;
    padding: 0;
    overflow: hidden;
    box-shadow: $boxShadow;

    &>* {
      transition: $transition;
    }
  }

  .detailWrap {
    width: calc(100% - 105px);
    display: flex;
    flex-direction: column;
    min-height: 96px;
    justify-content: center;
    padding: 10px 15px;
    font-weight: 600;
    text-align: start;
  }

  .productTitle {
    font-size: $fontSize20;
    line-height: 1.2;
    color: $plansInactiveTitleColor;
  }

  .productSubTitle {
    font-size: $fontSize14;
    line-height: 1.71;
    margin-top: 4px;
    font-weight: $fwb;
    color: $plansInactiveTextColor;
  }

  .lineThrough {
    font-size: $fontSize15;
    font-weight: 700;
    text-decoration-line: line-through;
  }

  .accentWrap {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: fit-content;
    min-width: 105px;
    max-width: 120px;
    padding: 5px 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background-color: $plansInactiveColor;
    color: $plansInactivePriceColor;
  }



  .accentTitle {
    font-size: $fontSize20;
    line-height: $fontSize32;
    font-weight: 600;
    width: 100%;
  }

  .accentTextLineThrough {
    text-decoration-line: line-through;
    text-decoration-color: $plansBadgeColor;
    text-decoration-thickness: 2px;
    color: $plansInactivePriceColor;
    font-size: $fontSize17;
    font-weight: 600;
    width: 100%;
  }

  .accentSubTitle {
    font-size: $fontSize12;
    line-height: 1.17;
    font-weight: $fwb;
    margin-top: 2px;
  }

  .lightAccentTitle {
    opacity: 0.6;
  }

  .lightAccentSubTitle {
    opacity: 0.8;
  }

  .afterTrialText {
    font-size: $fontSize12;
    font-weight: $fwb;
    opacity: 0.7;
    color: $plansInactiveTextColor;
  }

  .isActiveContainer {
    background: $plansPrimaryBg;
    border-color: $plansPrimaryBorderColor;

    .productTitle {
      color: $plansPrimaryTitleColor;
    }

    .productSubTitle {
      color: $plansPrimaryTextColor;
    }

    .lineThrough {
      text-decoration-line: line-through;
      color: $plansPrimaryTextColor;
    }

    .afterTrialText {
      color: $plansPrimaryTextColor;
    }

    .accentSubTitle {
      color: $white;
    }

    .accentWrap {
      background-color: $plansPrimaryColor;
      color: $plansPrimaryPriceColor;
    }

    .accentTextLineThrough {
      color: $plansPrimaryPriceColor;
    }
  }

  &.disabled {
    .accentWrap {
      background: $plansDisabledBgColor;
      color: $plansDisabledTextColor;
    }
  }
}

[dir='rtl'] .wrap {
  .accentWrap {
    right: unset;
    left: 0;
  }
}
